import React from 'react';
import { ResourceProps } from 'react-admin';

let resourceProps: ResourceProps = {
  name: 'users',
  list: React.lazy(() => import(/* webpackChunkName: "users-list" */ './list')),
  create: React.lazy(
    () => import(/* webpackChunkName: "users-create" */ './create')
  ),
  show: React.lazy(() => import(/* webpackChunkName: "users-show" */ './show')),
};

export default resourceProps;
