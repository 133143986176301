import { Admin } from 'react-admin';
import esLocale from 'date-fns/locale/es';
import { createBrowserHistory } from 'history';
import { RbacResource as Resource } from '@blackbox-vision/ra-rbac';

import { theme } from './core/theming';

import { menuItems } from './core/config/menu';

import { i18nProvider } from './core/providers/i18n';
import { authProvider } from './core/providers/auth';
import { dataProvider } from './core/providers/data';

import { canActivate } from './core/helpers/canActivate';

import AppLayout from './components/templates/app-layout';
import AppProvider from './components/templates/app-provider';

import CustomLogin from './pages/auth';

import usersResourceProps from './pages/users';
import productsResourceProps from './pages/products';
import currenciesResourceProps from './pages/currencies';
import jobsConfigResourceProps from './pages/jobs-config';
import investmentsResourceProps from './pages/investments';
import transactionsResourceProps from './pages/transactions';
import interestsResourceProps from './pages/interests';

let browserHistory = createBrowserHistory();

let App = () => {
  return (
    <AppProvider
      theme={theme}
      items={menuItems}
      locale={esLocale}
      canActivate={canActivate}
    >
      <Admin
        disableTelemetry
        layout={AppLayout}
        loginPage={CustomLogin}
        history={browserHistory}
        i18nProvider={i18nProvider}
        dataProvider={dataProvider}
        authProvider={authProvider}
      >
        <Resource {...usersResourceProps} />
        <Resource {...productsResourceProps} />
        <Resource {...currenciesResourceProps} />
        <Resource {...jobsConfigResourceProps} />
        <Resource {...investmentsResourceProps} />
        <Resource {...transactionsResourceProps} />
        <Resource {...interestsResourceProps} />
      </Admin>
    </AppProvider>
  );
};

export default App;
