import { makeStyles } from '@material-ui/core';

export let useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: '#3944A5',
  },
  card: {
    maxWidth: 350,
    padding: theme.spacing(4),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: 0,
  },
  submitButton: {
    marginTop: theme.spacing(),
  },
  forgotButton: {
    marginTop: theme.spacing(2),
    textTransform: 'none',
    fontWeight: 500,
    marginLeft: '0px !important',
  },
  input: {
    marginTop: '1em',
    minHeight: '68px',
  },
  content: {
    paddingBottom: theme.spacing(2),
  },
}));
