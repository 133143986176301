import React from 'react';
import { ResourceProps } from 'react-admin';

let resourceProps: ResourceProps = {
  name: 'currencies',
  list: React.lazy(
    () => import(/* webpackChunkName: "currencies-list" */ './list')
  ),
  create: React.lazy(
    () => import(/* webpackChunkName: "currencies-create" */ './create')
  ),
  edit: React.lazy(
    () => import(/* webpackChunkName: "currencies-edit" */ './edit')
  ),
  show: React.lazy(
    () => import(/* webpackChunkName: "currencies-show" */ './show')
  ),
};

export default resourceProps;
